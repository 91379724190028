import React from "react"
import Link from "gatsby-link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Para, Button } from "../components/basic"

export default () => (
  <Layout hideHeader alwaysDarkLogo>
    <SEO />
    <div
      style={{
        marginTop: "100px",
        textAlign: "center",
      }}
    >
      <Para>Thanks for reaching out. We will call you on the number provided</Para>

      <Link to="/">
        <Button gradientBg>Go Back</Button>
      </Link>
    </div>
  </Layout>
)
